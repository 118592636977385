import React, { Component } from "react";

class Careers extends Component {
    render(){
        return(
            <p>hello, careers</p>
        )
    }
}

export default Careers;