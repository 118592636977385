import React, { Component } from "react";

class Contact extends Component {
    render(){
        return(
            <p>hello, contact</p>
        )
    }
}

export default Contact;