import React, { Component } from "react";

class Home extends Component {
    render(){
        return(
            <p>hello, home</p>
        )
    }
}

export default Home;